*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  font-family: acumin-pro, system-ui, sans-serif;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-size: 14px;
  background-color: #f4f4f4;
  align-items: start;
  min-height: 100vh;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  color: #2f2f2f;
  background-color: rgb(67, 68, 86);
}

.footer > * {
  flex: 1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-left: 16%;
  margin-bottom: 0%;
  text-align: center;
}

.footer__logo {
  font-family: "Pacifico", cursive !important;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
  margin-left: 0%;
}

.footer__logo1 {
  font-family: "Pacifico", cursive !important;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
  color: #999;
  justify-content: center;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #999;
}

.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}

.nav__ul__item {
  width: 100%;
  margin-top: 3%;
  text-align: initial;
  padding-left: 3%;
  padding-right: 3%;
}

.fnav__ul__item {
  margin-left: 12%;
}

.heart {
  color: white;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }

  .nav__item--extra {
    flex-grow: 2;
    margin-top: 3%;
  }

  .nav__item {
    margin-top: 3%;
    text-align: center;
  }

  .footer__addr {
    flex: 1 0px;
    margin-right: 1.25em;
    margin-left: 9%;
    margin-bottom: 0%;
    text-align: center;
  }

  .footer__nav {
    flex: 2 0px;
  }

  .footer__contact {
    margin-top: 1.3em;
    margin-left: 0%;
    font-size: 15px;
    font-weight: 400;
  }
}

@media only screen and (min-width: 1400px) {
  .legal {
    margin-top: 3% !important;
    display: flex;
    flex-wrap: wrap;
    color: #999;
    justify-content: center;
  }

  .legal__links {
    display: block !important;
    position: sticky !important;
    align-items: center;
    padding-left: 12.5%;
    margin-top: 5%;
    color: #999;
  }

  .footer__btn {
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: #999;
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    margin-top: 3%;
    font-size: 1rem;
    padding: 0.75rem;
  }

  .nav__title {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 3%;
    margin-left: 12% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-device-width: 1400px) {
  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
    margin-left: 15.5%;
  }

  .legal__links {
    display: block !important;
    position: sticky !important;
    align-items: center;
    margin-left: 64% !important;
    margin-top: -3% !important;
    color: #999;
  }

  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: #999;
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    margin-top: 3%;
    margin-left: 37%;
    font-size: 1rem;
    padding: 0 1.3em;
  }

  .nav__title {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 3%;
    margin-left: 13%;
    text-align: center;
  }
}

@media only screen and (min-width: 480px) and (max-device-width: 1024px) {
  .nav__item {
    margin-top: 23%;
    text-align: center;
  }

  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
    margin-left: 6.5%;
  }

  .legal__links {
    display: block !important;
    position: sticky !important;
    align-items: center;
    margin-left: 62% !important;
    margin-top: -3% !important;
    color: #999;
  }

  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: #999;
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    margin-top: 3%;
    margin-left: 25%;
    font-size: 1rem;
    padding: 0 1.3em;
  }

  .nav__title {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 3%;
    margin-left: 13%;
    text-align: center;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer__addr {
    margin-right: 1.25em;
    margin-left: 2%;
    margin-bottom: 0%;
    text-align: center;
  }

  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: #999;
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    margin-top: 3%;
    margin-left: 32%;
    font-size: 1rem;
    padding: 0 1.3em;
  }

  .nav__ul {
    margin: 0%;
    margin-left: 15%;
  }

  .fnav__ul__item {
    margin: 1%;
    margin-left: 2%;
    margin-left: 2%;
    padding: 1%;
  }

  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
    margin-left: 5%;
    padding-top: 3%;
  }

  .legal__links {
    display: grid;
    align-items: center;
    margin-left: 2% !important;
    padding-top: 0%;
    margin-bottom: 3%;
    color: #999;
    text-align: center;
  }

  .nav__title {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 3%;
    margin-left: 2%;
    text-align: center;
  }
}
